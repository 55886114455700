/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.registeradd = value
        state.registerlist = !value
        state.formaciones = []
        state.intervenciones = []
        state.intermediaciones = []
    },

    setRegistros(state, value) {
        // if (value.index < 0) {
        //     state.registros.push(value)
        // } else {
        //     Object.assign(state.registros[value.index], value)
        // }
        // state.registros.push(value)
        state.registros = value
    },

    setUpdateRegistros(state, value) {
        Object.assign(state.registros[value.index], value)
    },

    setRegistro(state, value) {
        state.registros.push(value)
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setLoaderFormacion(state) {
        state.loadingForm = !state.loadingForm
    },

    setLoaderFormacionSave(state) {
        state.loadingFormSave = !state.loadingFormSave
    },

    setFormaciones(state, value) {
        state.formaciones = value
    },

    setFormacion(state, value) {
        state.formaciones.push(value)
    },

    setUpdateFormaciones(state, value) {
        Object.assign(state.formaciones[value.index], value)
    },

    setLoaderIntervenciones(state) {
        state.loadingInterv = !state.loadingInterv
    },

    setLoaderIntervencionesSave(state) {
        state.loadingIntervSave = !state.loadingIntervSave
    },

    setIntervenciones(state, value) {
        state.intervenciones = value
    },

    setIntervencion(state, value) {
        state.intervenciones.push(value)
    },

    setUpdateIntervenciones(state, value) {
        Object.assign(state.intervenciones[value.index], value)
    },

    setLoaderIntermediaciones(state) {
        state.loadingInterm = !state.loadingInterm
    },

    setLoaderIntermediacionesSave(state) {
        state.loadingIntermSave = !state.loadingIntermSave
    },

    setIntermediaciones(state, value) {
        state.intermediaciones = value
    },

    setIntermediacion(state, value) {
        state.intermediaciones.push(value)
    },

    setUpdateIntermediaciones(state, value) {
        Object.assign(state.intermediaciones[value.index], value)
    },

    setLoaderDownload(state) {
        state.loadingDownload = !state.loadingDownload
    },

    setLoaderOfertas(state) {
        state.loadingOfertas = !state.loadingOfertas
    },

    setLoaderDocumento(state) {
        state.loadingDocumento = !state.loadingDocumento
    },

    setLoaderDownload2(state) {
        state.loadingDownload2 = !state.loadingDownload2
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addOfertantes({ commit, dispatch }, ofertante) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/ofertantes', ofertante)
                .then(response => {
                    commit('setRegister', false)
                    commit('setOfertante', response.data.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateOfertantes({ commit, dispatch }, ofertante) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = ofertante.id
            axios.put(`/ofertantes/${id}`, ofertante)
                .then(response => {
                    commit('setRegister', false)
                        // commit('setUpdateCategorias', categoria)
                    dispatch('getOfertantes')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response) {
                        if (err.response.data.code === 401) {
                            Vue.notify({
                                group: 'loggedIn',
                                text: 'Su sesión ha caducado',
                                type: 'red accent-2',
                                duration: 5000,
                            })
                            dispatch('logout', null, { root: true })
                        }
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getOfertantes({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/ofertantes')
                .then(response => {
                    commit('setOfertantes', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteOfertantes({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/ofertantes/${id}`)
                .then(response => {
                    dispatch('getOfertantes')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    async reportOfertantes({ commit, dispatch }, params) {
        commit('setLoader')
        return await axios.post('/ofertantes/download/pdf', params, {
                responseType: 'blob',
            })
            .then(response => {
                commit('setLoader')
                return response
            })
            .catch(err => {
                if (err.response.data.code === 401) {
                    commit('setLoader')
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Su sesión ha caducado',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                    dispatch('logout', null, { root: true })
                } else {
                    commit('setLoader')
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Hubo un problema al descargar',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                }
            })
    },
}
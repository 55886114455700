/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setLoader(state) {
        state.loading = !state.loading
    },

    setAgendas(state, value) {
        state.agendas = value
    },

    setAgenda(state, value) {
        state.agendas.push(value)
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
/* eslint-env es6 */
/* eslint-disable func-call-spacing */
import Vue from 'vue'
import Router from 'vue-router'
import { store } from './store/store'
import { Role } from '@/constants/role'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            redirect: {
                path: '/admin/agenda',
            },
        },
        {
            path: '/',
            component: () =>
                import ('@/views/dashboard/NoAuth'),
            children: [
                // Login
                {
                    name: 'Login',
                    path: 'login',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/Login'),
                },
                // Logout
                {
                    name: 'Logout',
                    path: 'logout',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/components/core/Logout'),
                },
                // RecoverPassword
                {
                    name: 'RecoverPassword',
                    path: 'recoverpassword',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/RecoverPassword'),
                },
                // Unauthorize
                {
                    name: 'Unauthorized',
                    path: 'unauthorized',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/Unauthorized'),
                },
            ],
        },
        {
            path: '/admin',
            meta: {
                requiresAuth: true,
                authorize: [Role.Admin],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'Agenda',
                    path: 'agenda',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/admin/Agenda'),
                },
                {
                    name: 'Registros',
                    path: 'registros',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/systempages/registertable'),
                },
                {
                    name: 'Documentos',
                    path: 'documentos',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/admin/Documentos'),
                },
                {
                    name: 'Gestor Documentos',
                    path: 'gestorDoc',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/admin/gestorDocumentos'),
                },
                {
                    name: 'Usuarios',
                    path: 'usuarios',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/user/Usuarios'),
                },
                {
                    name: 'Categorias',
                    path: 'category',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/admin/Categorias'),
                },
                {
                    name: 'Perfil',
                    path: 'profile',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/component/Profile'),
                },
                {
                    name: 'Ofertantes',
                    path: 'ofertantes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/ofertantes/ofertantes'),
                },
                {
                    name: 'Ofertas',
                    path: 'ofertas',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/ofertas/ofertas'),
                },
                {
                    name: 'Formaciones',
                    path: 'formaciones',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/formaciones/formacionesList'),
                },
                {
                    name: 'Auditoría',
                    path: 'audit',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/audit/auditList'),
                },
                {
                    name: 'Contactos',
                    path: 'contact',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/contact/contactos'),
                },
            ],
        },
        {
            path: '/user',
            meta: {
                requiresAuth: true,
                authorize: [Role.User],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'Home usuario',
                    path: 'home',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.User],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/user/Home'),
                },
            ],
        },
    ],
})

router.beforeEach((to, from, next) => {
    const authorize = to.meta.authorize
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.authorized) {
            if (authorize.length && authorize.includes(localStorage.getItem('role'))) {
                next()
            } else {
                next('/unauthorized')
            }
        } else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router
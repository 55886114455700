/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addAgenda({ commit, dispatch }, agenda) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/agenda', agenda)
                .then(response => {
                    // dispatch('getAgendas')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateAgenda({ commit, dispatch }, agenda) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = agenda.id
            axios.put(`/agenda/${id}`, agenda)
                .then(response => {
                    // dispatch('getAgendas')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getAgendas({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/agenda', params)
                .then(response => {
                    commit('setAgendas', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteAgenda({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/agenda/${id}`)
                .then(response => {
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getEventoById({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get(`/agenda/${id}`)
                .then(response => {
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    reject(err)
                })
        })
    },
}
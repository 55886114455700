/* eslint eol-last: ["error", "never"] */
import state from './states'
import actions from './actions'
import mutations from './mutations'

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
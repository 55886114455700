/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    getAudit({ commit, dispatch }, item) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get(`/audit/${item}`)
                .then(response => {
                    commit('setAudit', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addDocumento({ commit, dispatch }, documento) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/documents', documento, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    commit('setRegister', false)
                    dispatch('getDocumentos')
                    dispatch('getItemsDocumentos')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateDocumento({ commit, dispatch }, documento) {
        return new Promise((resolve, reject) => {
            const id = documento.get('id')
            commit('setLoader')
            axios.post(`/documents/${id}`, documento, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    commit('setRegister', false)
                    dispatch('getDocumentos')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getDocumentos({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/documents')
                .then(response => {
                    commit('setDocumentosList', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getItemsDocumentos({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            // commit('setLoader')
            commit('LOADER', true, { root: true })
            axios.get('/items/documents')
                .then(response => {
                    commit('setDocumentos', response.data)
                        // commit('setLoader')
                    commit('LOADER', false, { root: true })
                    resolve(response)
                })
                .catch(err => {
                    // commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getItemsDocumentosFilter({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            // commit('setLoader')
            axios.get('/items/documents/filter')
                .then(response => {
                    commit('setDocumentosFilter', response.data)
                        // commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async downloadDocument({ commit, dispatch }, id) {
        commit('LOADER', true, { root: true })
        return await axios({
                method: 'get',
                url: `/documents/file/${id}`,
                responseType: 'blob',
            })
            .then(response => {
                return response
            })
    },

    async getNombreDocument({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/documents/${id}`)
                .then(response => {
                    commit('LOADER', false, { root: true })
                    resolve(response.data.name)
                })
                .catch(err => {
                    commit('LOADER', false, { root: true })
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteDocumento({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/documents/${id}`)
                .then(response => {
                    dispatch('getDocumentos')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },
}
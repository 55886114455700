<template>
  <v-app>
    <router-view />
    <notifications
      group="loggedIn"
      position="bottom center"
      animation-type="velocity"
    />
  </v-app>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addregistros({ commit, dispatch }, registro) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/usuarias', registro)
                .then(response => {
                    // commit('setRegister', false)
                    // commit('setRegistro', response.data.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateregistros({ commit, dispatch }, registro) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = registro.id
            axios.put(`/usuarias/${id}`, registro)
                .then(response => {
                    commit('setRegister', false)
                    // commit('setUpdateRegistros', registro)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getregistros({ commit, dispatch }, params) {
        // console.log(params)
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/usuarias', { params })
                .then(response => {
                    // console.log(response)
                    commit('setRegistros', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getregistrosByTipoId({ commit, dispatch }, datos) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            // axios.get(`/usuarias/find?tipo=${datos.tipo}&dni=${datos.dni}`)
            axios.get(`/usuarias/find?dni=${datos.dni}`)
                .then(response => {
                    commit('setRegistros', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteUsuaria({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/usuarias/${id}`)
                .then(response => {
                    dispatch('getregistros')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getFormaciones({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacion')
            axios.get('/formacion')
                .then(response => {
                    commit('setFormaciones', response.data)
                    commit('setLoaderFormacion')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacion')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getFormacionUsuariaId({ commit, dispatch }, usuaria) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacion')
            axios.get(`/formacion/${usuaria}`)
                .then(response => {
                    commit('setFormaciones', response.data)
                    commit('setLoaderFormacion')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacion')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    /* addFormaciones({ commit, dispatch }, formacion) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacionSave')
            axios.post('/formacion', formacion)
                .then(response => {
                    commit('setFormacion', response.data.data)
                    commit('setLoaderFormacionSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    }, */

    /* updateFormaciones({ commit, dispatch }, formacion) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacionSave')
            const id = formacion.id
            axios.put(`/formacion/${id}`, formacion)
                .then(response => {
                    // commit('setUpdateFormaciones', formacion)
                    commit('setLoaderFormacionSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    }, */

    deleteFormacion({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacion')
            axios.delete(`/formacion/${id}`)
                .then(response => {
                    dispatch('getFormaciones')
                    commit('setLoaderFormacion')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacion')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getIntervenciones({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntervenciones')
            axios.get('/intervenciones')
                .then(response => {
                    commit('setIntervenciones', response.data)
                    commit('setLoaderIntervenciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntervenciones')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getIntervencionesUsuariaId({ commit, dispatch }, usuaria) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntervenciones')
            axios.get(`/intervenciones/${usuaria}`)
                .then(response => {
                    commit('setIntervenciones', response.data)
                    commit('setLoaderIntervenciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntervenciones')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    addIntervenciones({ commit, dispatch }, intervenciones) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderIntervencionesSave')
            axios.post('/intervenciones', intervenciones)
                .then(response => {
                    commit('setIntervencion', response.data.data)
                    commit('setLoaderIntervencionesSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntervencionesSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateIntervenciones({ commit, dispatch }, intervenciones) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntervencionesSave')
            const id = intervenciones.id
            axios.put(`/intervenciones/${id}`, intervenciones)
                .then(response => {
                    // commit('setUpdateIntervenciones', intervenciones)
                    commit('setLoaderIntervencionesSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntervencionesSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteIntervencion({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntervenciones')
            axios.delete(`/intervenciones/${id}`)
                .then(response => {
                    // dispatch('getIntervenciones')
                    commit('setLoaderIntervenciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntervenciones')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getIntermediacion({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntermediaciones')
            axios.get('/intermediacion')
                .then(response => {
                    commit('setIntermediaciones', response.data)
                    commit('setLoaderIntermediaciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntermediaciones')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getIntermediacionUsuariaId({ commit, dispatch }, usuaria) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntermediaciones')
            axios.get(`/intermediacion/${usuaria}`)
                .then(response => {
                    commit('setIntermediaciones', response.data)
                    commit('setLoaderIntermediaciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntermediaciones')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    addIntermediacion({ commit, dispatch }, intermediacion) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderIntermediacionesSave')
            axios.post('/intermediacion', intermediacion)
                .then(response => {
                    commit('setIntermediacion', response.data.data)
                    commit('setLoaderIntermediacionesSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntermediacionesSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateIntermediacion({ commit, dispatch }, intermediacion) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntermediacionesSave')
            const id = intermediacion.id
            axios.put(`/intermediacion/${id}`, intermediacion)
                .then(response => {
                    // commit('setUpdateIntermediaciones', intermediacion)
                    commit('setLoaderIntermediacionesSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntermediacionesSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteIntermediacion({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoaderIntermediaciones')
            axios.delete(`/intermediacion/${id}`)
                .then(response => {
                    // dispatch('getIntermediacion')
                    commit('setLoaderIntermediaciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderIntermediaciones')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    async downloadFirma({ commit, dispatch }, id) {
        commit('setLoaderDownload')
        return await axios({
                method: 'get',
                url: `/download/pdf/${id}`,
                responseType: 'blob',
            })
            .then(response => {
                commit('setLoaderDownload')
                return response
            })
            .catch(err => {
                commit('setLoaderDownload')
                if (err.response.data.code === 401) {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Su sesión ha caducado',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                    dispatch('logout', null, { root: true })
                } else {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Hubo un problema al descargar',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                }
            })
    },

    ofertasUsuarias({ commit, dispatch }, ofertas) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderOfertas')
            axios.post(`/ofertas/${ofertas.id}`, ofertas)
                .then(response => {
                    commit('setLoaderOfertas')
                    dispatch('getregistros')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderOfertas')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    formacionesUsuarias({ commit, dispatch }, formaciones) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderFormacionSave')
            axios.post(`/formaciones-usuaria/${formaciones.id}`, formaciones)
                .then(response => {
                    commit('setLoaderFormacionSave')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    addDocumentoUsuaria({ commit, dispatch }, documento) {
        return new Promise((resolve, reject) => {
            axios.post('/usuaria/document/file', documento, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    dispatch('getregistros')
                    commit('setLoaderDocumento')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderDocumento')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteDocumentoUsuaria({ commit, dispatch }, documento) {
        return new Promise((resolve, reject) => {
            axios.delete(`/usuaria/document/file/${documento}`)
                .then(response => {
                    dispatch('getregistros')
                    resolve(response)
                })
                .catch(err => {
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    async downloadDocumentUsuaria({ commit, dispatch }, id) {
        commit('setLoaderDownload2')
        return await axios({
                method: 'get',
                url: `/usuaria/document/file/${id}`,
                responseType: 'blob',
            })
            .then(response => {
                commit('setLoaderDownload2')
                return response
            })
            .catch(err => {
                commit('setLoaderDownload2')
                if (err.response.data.code === 401) {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Su sesión ha caducado',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                    dispatch('logout', null, { root: true })
                } else {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Hubo un problema al descargar',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                }
            })
    },

    async printer({ commit, dispatch }, id) {
        commit('LOADER', true, { root: true })
        return await axios({
                method: 'get',
                url: `/usuaria/download/pdf/${id}`,
                responseType: 'blob',
            })
            .then(response => {
                commit('LOADER', false, { root: true })
                return response
            })
            .catch(err => {
                commit('LOADER', false, { root: true })
                if (err.response.data.code === 401) {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Su sesión ha caducado',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                    dispatch('logout', null, { root: true })
                } else {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Hubo un problema al descargar',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                }
            })
    },

    async getNacionalidades({ commit, dispatch }) {
        return await axios.get('/usuaria/nacionalidades')
            .then(response => {
                return response
            })
            .catch(err => {
                console.log(err)
            })
    },

    async reportUsuarias({ commit, dispatch }, params) {
        commit('setLoader')
        return await axios.post('/usuaria/download/pdf', params, {
                responseType: 'blob',
            })
            .then(response => {
                commit('setLoader')
                return response
            })
            .catch(err => {
                if (err.response.data.code === 401) {
                    commit('setLoader')
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Su sesión ha caducado',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                    dispatch('logout', null, { root: true })
                } else {
                    commit('setLoader')
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Hubo un problema al descargar',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                }
            })
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.ofertantesadd = value
        state.ofertanteslist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setOfertantes(state, value) {
        state.ofertantes = value
    },

    setOfertante(state, value) {
        state.ofertantes.push(value)
    },

    setUpdateOfertantes(state, value) {
        Object.assign(state.ofertantes[value.index], value)
    },

    setDocumentos(state, value) {
        state.documentos = value
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addCategoria({ commit, dispatch }, categoria) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/categorias', categoria)
                .then(response => {
                    commit('setRegister', false)
                        // commit('setCategoria', response.data.data)
                    dispatch('getCategorias')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateCategoria({ commit, dispatch }, categoria) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = categoria.id
            axios.put(`/categorias/${id}`, categoria)
                .then(response => {
                    commit('setRegister', false)
                        // commit('setUpdateCategorias', categoria)
                    dispatch('getCategorias')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response) {
                        if (err.response.data.code === 401) {
                            Vue.notify({
                                group: 'loggedIn',
                                text: 'Su sesión ha caducado',
                                type: 'red accent-2',
                                duration: 5000,
                            })
                            dispatch('logout', null, { root: true })
                        }
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getCategorias({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/categorias')
                .then(response => {
                    commit('setCategorias', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteCategoria({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/categorias/${id}`)
                .then(response => {
                    dispatch('getCategorias')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },
}
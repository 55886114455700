/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addUsuario({ commit, dispatch }, usuario) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/usuarios', usuario)
                .then(response => {
                    commit('setRegister', false)
                    commit('setUsuario', response.data.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateProfile({ commit, dispatch }, usuario) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = usuario.id
            axios.put(`/usuarios/${id}`, usuario)
                .then(response => {
                    commit('setRegister', false)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response) {
                        if (err.response.data.code === 401) {
                            Vue.notify({
                                group: 'loggedIn',
                                text: 'Su sesión ha caducado',
                                type: 'red accent-2',
                                duration: 5000,
                            })
                            dispatch('logout', null, { root: true })
                        }
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateUsuario({ commit, dispatch }, usuario) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = usuario.id
            axios.put(`/usuarios/${id}`, usuario)
                .then(response => {
                    commit('setRegister', false)
                    commit('setUpdateUsuarios', usuario)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response) {
                        if (err.response.data.code === 401) {
                            Vue.notify({
                                group: 'loggedIn',
                                text: 'Su sesión ha caducado',
                                type: 'red accent-2',
                                duration: 5000,
                            })
                            dispatch('logout', null, { root: true })
                        }
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getUsuarios({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/usuarios')
                .then(response => {
                    commit('setUsuarios', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getUserAuth({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.get('/user/auth')
                .then(response => {
                    // commit('setUsuarios', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteUsuario({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/usuarios/${id}`)
                .then(response => {
                    dispatch('getUsuarios')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },
}
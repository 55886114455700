/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.contactosadd = value
        state.contactoslist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setContactos(state, value) {
        state.contactos = value
    },

    setContacto(state, value) {
        state.contactos.push(value)
    },

    setUpdateContactos(state, value) {
        Object.assign(state.contactos[value.index], value)
    },

    setDocumentos(state, value) {
        state.documentos = value
    },
}
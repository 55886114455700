/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.formacionesadd = value
        state.formacioneslist = !value
    },

    setLoaderFormacion(state) {
        state.loadingForm = !state.loadingForm
    },

    setLoaderFormacionSave(state) {
        state.loadingFormSave = !state.loadingFormSave
    },

    setFormaciones(state, value) {
        state.formaciones = value
    },

    setFormacion(state, value) {
        state.formaciones.push(value)
    },

    setUpdateFormaciones(state, value) {
        Object.assign(state.formaciones[value.index], value)
    },

    setLoaderDownload(state) {
        state.loadingDownload = !state.loadingDownload
    },
}
// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import VueTelInput from 'vue-tel-input'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VuetifyConfirm from 'vuetify-confirm'
import DatetimePicker from 'vuetify-datetime-picker'
import signature from './plugins/signature'

Vue.use(VueTelInputVuetify, {
    vuetify,
})

Vue.use(VuetifyConfirm, {
    vuetify,
})

Vue.use(DatetimePicker, {
    vuetify,
})

const bus = new Vue()
Vue.prototype.$http = axios
    // Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.get.Accept = 'application/json'

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

Vue.config.productionTip = false
Vue.use(VueTelInput)
Vue.use(Notifications, { velocity })

// Development Notification
Vue.prototype.$inDevelopment = function() {
    Vue.notify({
        group: 'loggedIn',
        text: 'Feature in development',
        type: 'light-blue darken-2',
        duration: 5000,
    })
}

// Filters
Vue.filter('recorte', function(texto) {
    return texto.substring(0, 30) + '...'
})

new Vue({
    router,
    store,
    vuetify,
    i18n,
    signature,
    components: { App },
    render: h => h(App),
}).$mount('#app')

export default bus
import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

// modules
import auth from './auth'
import registros from './registros'
import agenda from './agenda'
import usuarios from './usuarios'
import categorias from './categorias'
import documentos from './documentos'
import ofertas from './ofertas'
import ofertantes from './ofertantes'
import audit from './audit'
import formaciones from './formaciones'
import contactos from './contactos'

Vue.use(Vuex)

/* eslint eol-last: ["error", "never"] */
export const store = new Vuex.Store({
    // plugins: [createPersistedState()],
    modules: {
        auth,
        registros,
        agenda,
        usuarios,
        categorias,
        documentos,
        ofertas,
        ofertantes,
        audit,
        formaciones,
        contactos,
    },
})
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.viewaudit = value
        state.auditlist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setAudit(state, value) {
        state.audit = value
    },
}
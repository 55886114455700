/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.categoriasadd = value
        state.categoriaslist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setCategorias(state, value) {
        state.categorias = value
    },

    setCategoria(state, value) {
        state.categorias.push(value)
    },

    setUpdateCategorias(state, value) {
        Object.assign(state.categorias[value.index], value)
    },

    setDocumentos(state, value) {
        state.documentos = value
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.ofertasadd = value
        state.ofertaslist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setOfertas(state, value) {
        state.ofertas = value
    },

    setOferta(state, value) {
        state.ofertas.push(value)
    },

    setUpdateOfertas(state, value) {
        Object.assign(state.ofertas[value.index], value)
    },

    setDocumentos(state, value) {
        state.documentos = value
    },
}
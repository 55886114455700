/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.documentosadd = value
        state.documentoslist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setDocumentosList(state, value) {
        state.documentosListado = value
    },

    setDocumentos(state, value) {
        state.documentos = value
    },

    setDocumentosFilter(state, value) {
        state.documentosFilter = value
    },
}
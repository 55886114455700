/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    addContactos({ commit, dispatch }, contactos) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.post('/contactos', contactos)
                .then(response => {
                    commit('setRegister', false)
                    commit('setContacto', response.data.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateContactos({ commit, dispatch }, contactos) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            const id = contactos.id
            axios.put(`/contactos/${id}`, contactos)
                .then(response => {
                    commit('setRegister', false)
                        // commit('setUpdateCategorias', categoria)
                    dispatch('getContactos')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response) {
                        if (err.response.data.code === 401) {
                            Vue.notify({
                                group: 'loggedIn',
                                text: 'Su sesión ha caducado',
                                type: 'red accent-2',
                                duration: 5000,
                            })
                            dispatch('logout', null, { root: true })
                        }
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getContactos({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            // const respuesta = [{"id":1,"nombre_apellido":"Ana Garcia","direccion":"Calle Albarrac\u00edn 48","email":"aga@hotmail.com","telefono":"+34 698 69 08 76","ofertas":[{"id":1,"oferta":"Servicio al hogar","condiciones":"Proactividad","candidata":"Juliana Soto"}],"fecha":"11\/04\/2023"}]
            // resolve(respuesta)
            axios.get('/contactos')
                .then(response => {
                    commit('setContactos', response.data)
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteContactos({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoader')
            axios.delete(`/contactos/${id}`)
                .then(response => {
                    dispatch('getContactos')
                    commit('setLoader')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoader')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },
}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
// import axios from 'axios';
// import lang from '../../locales/en';
import Vue from 'vue'
import axios from 'axios'

export default {
    getFormaciones({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacion')
            axios.get('/formacion')
                .then(response => {
                    commit('setFormaciones', response.data)
                    commit('setLoaderFormacion')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacion')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    addFormaciones({ commit, dispatch }, formacion) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacionSave')
            axios.post('/formacion', formacion, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    commit('setRegister', false)
                    commit('setFormacion', response.data.data)
                    commit('setLoaderFormacionSave')
                    dispatch('getFormaciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    updateFormaciones({ commit, dispatch }, formacion) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacionSave')
            const id = formacion.get('id')
            axios.post(`/formacion/${id}`, formacion, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    const data = {
                        index: formacion.get('index'),
                        id: formacion.get('id'),
                        tipo: formacion.get('tipo'),
                        fecha: formacion.get('fecha'),
                        fecha_hasta: formacion.get('fecha_hasta'),
                        total_horas: formacion.get('total_horas'),
                        horario_imparticion: formacion.get('horario_imparticion'),
                        plazas: formacion.get('plazas'),
                        /* idioma: formacion.get('idioma'), */
                        descripcion: formacion.get('descripcion'),
                    }
                    /* commit('setRegister', false) */
                    commit('setUpdateFormaciones', data)
                    commit('setLoaderFormacionSave')
                    dispatch('getFormaciones')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteFormacion({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoaderFormacion')
            axios.delete(`/formacion/${id}`)
                .then(response => {
                    dispatch('getFormaciones')
                    commit('setLoaderFormacion')
                    resolve(response)
                })
                .catch(err => {
                    commit('setLoaderFormacion')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    getFormacionUsuarias({ commit, dispatch }, formacionId) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderFormacionSave')
            axios.get(`/formacion-usuaria/${formacionId}`)
                .then(response => {
                    /* commit('setRegister', false)
                    commit('setFormacion', response.data.data)
                    commit('setLoaderFormacionSave')
                    dispatch('getFormaciones') */
                    resolve(response)
                })
                .catch(err => {
                    // commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    addFormacionUsuaria({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderFormacionSave')
            axios.post(`/formacion-usuaria/${params.usuariaId}/${params.formacionId}`)
                .then(response => {
                    /* commit('setRegister', false)
                    commit('setFormacion', response.data.data)
                    commit('setLoaderFormacionSave')
                    dispatch('getFormaciones') */
                    resolve(response)
                })
                .catch(err => {
                    // commit('setLoaderFormacionSave')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteFormacionUsuaria({ commit, dispatch }, params) {
        return new Promise((resolve, reject) => {
            // commit('setLoaderFormacion')
            axios.delete(`/formacion-usuaria/${params.usuariaId}/${params.formacionId}`)
                .then(response => {
                    /* dispatch('getFormaciones')
                    commit('setLoaderFormacion') */
                    resolve(response)
                })
                .catch(err => {
                    // commit('setLoaderFormacion')
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    deleteFile({ commit, dispatch }, idFormacion) {
        return new Promise((resolve, reject) => {
            axios.put(`/formacion/file/${idFormacion}`)
                .then(response => {
                    dispatch('getFormaciones')
                    resolve(response)
                })
                .catch(err => {
                    if (err.response.data.code === 401) {
                        Vue.notify({
                            group: 'loggedIn',
                            text: 'Su sesión ha caducado',
                            type: 'red accent-2',
                            duration: 5000,
                        })
                        dispatch('logout', null, { root: true })
                    } else {
                        reject(err)
                    }
                })
        })
    },

    async downloadFile({ commit, dispatch }, idFormacion) {
        commit('setLoaderDownload')
        return await axios({
                method: 'get',
                url: `/formacion/file/${idFormacion}`,
                responseType: 'blob',
            })
            .then(response => {
                commit('setLoaderDownload')
                return response
            })
            .catch(err => {
                commit('setLoaderDownload')
                if (err.response.data.code === 401) {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Su sesión ha caducado',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                    dispatch('logout', null, { root: true })
                } else {
                    Vue.notify({
                        group: 'loggedIn',
                        text: 'Hubo un problema al descargar',
                        type: 'red accent-2',
                        duration: 5000,
                    })
                }
            })
    },

}
/* eslint eol-last: ["error", "never"] */
/* eslint space-before-function-paren: ["error", "never"] */
export default {
    setRegister(state, value) {
        state.usuariosadd = value
        state.usuarioslist = !value
    },

    setLoader(state) {
        state.loading = !state.loading
    },

    setUsuarios(state, value) {
        state.usuarios = value
    },

    setUsuario(state, value) {
        state.usuarios.push(value)
    },

    setUpdateUsuarios(state, value) {
        Object.assign(state.usuarios[value.index], value)
    },
}